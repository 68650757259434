import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Container, Flex, Box, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentButtons from '@solid-ui-components/ContentButtons'
import ContentContainer from '@solid-ui-components/ContentContainer'
import { ImQuotesRight } from 'react-icons/im'
import getImage from '@solid-ui-components/utils/getImage'

const styles = {
  avatar: {
    size: 120,
    display: `block`,
    bg: `omegaLighter`,
    borderTopColor: `omegaLighter`,
    borderTopWidth: `lg`,
    borderTopStyle: `solid`,
    borderRadius: `full`,
    boxSizing: `content-box`,
    mx: `auto`,
    mt: -90,
    mb: 3
  }
}

const TestimonialsBlock01 = ({ content: { text, collection } }) => (
  <Container>
    <Box sx={{ textAlign: `center` }}>
      <ContentText content={text} />
    </Box>
    <Divider />
    <Divider />
    <Flex sx={{ mx: -3, flexWrap: `wrap` }}>
      {collection?.map(({ container, avatar, text, buttons }, index) => (
        <Box
          key={`item-${index}`}
          sx={{
            flexBasis: [`1`, null, `1/2`, `1/4`],
            flexGrow: 1,
            p: 3
          }}
        >
          <Reveal effect='fadeInLeft' delay={0.25 * (index + 2)}>
            <ContentContainer
              content={container}
              variant='cards.paper'
              sx={{ textAlign: `center` }}
            >
              <Img
                image={getImage(avatar?.src)}
                alt={avatar?.alt}
                objectPosition='top center'
                css={css(styles.avatar)}
              />
              <ImQuotesRight css={css({ size: `icon.xs`, color: `alpha` })} />
              <ContentText content={text} />
              <ContentButtons content={buttons} />
              <Divider space={3} />
            </ContentContainer>
            <Divider space={4} />
            <Divider />
          </Reveal>
        </Box>
      ))}
    </Flex>
  </Container>
)

export default TestimonialsBlock01
